<template>
	<div class="">
		<DrilldownHolder v-if="drilldownOverlayShow" :drilldownreporttype="drilldownReportType"
			:drilldownitem="drilldownitem" v-on:closedrilldown="drilldownOverlayShow = false"
			:selections="selections" :drilldownpropname="drilldownpropname" :fingerprintreturndata="returndata"
		></DrilldownHolder>
		<div v-if="started" class="content-container report-section" :class="{sectionhide : drilldownOverlayShow === true}">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
					</h2>
					<br/><br/>
					<p>Distinct Tracks (Universe Size): {{distinctTracks}}</p>
					<p>Exclusive Tracks: {{exclusiveTracks}}</p>
					<p>Total Spins in period: {{totalSpins}}</p>
				</div>

			</div>

			<div class="structure">
				<div class="chart-container">
					<p class="chart-heading">Shared Songs</p>
					<SharedSongs :chartdata="sharedsongsdata" :chartcategories="sharedsongcategories"
						v-on:songlistdrill="songlistDrilldown('StationFingerprintSongList', $event)"
					></SharedSongs>
				</div>

				<div class="chart-container">
					<p class="chart-heading">Era Breakdown</p>
					<EraBreakdown :chartdata="erabreakdowndata" :chartcategories="erabreakdowncategories"
						v-on:songlistdrill="drillDown($event, 'Era Breakdown')"
					></EraBreakdown>
				</div>

				<div class="chart-container">
					<p class="chart-heading">Song Counts</p>
					<SongCounts :chartdata="songcountdata" :chartcategories="songcountcategories"
						v-on:songlistdrill="drillDownUniverse($event)"
					></SongCounts>
				</div>

				<div class="chart-container">
					<p class="chart-heading">Percentage Music</p>
					<PercentageMusic :chartdata="pctmusicdata" :chartcategories="pctmusiccategories"></PercentageMusic>
				</div>

				<div class="chart-container" v-if="tempobreakdowndata.length > 0">
					<p class="chart-heading">Tempo Breakdown</p>
					<TempoBreakdown :chartdata="tempobreakdowndata" :chartcategories="tempobreakdowncategories"
						v-on:songlistdrill="drillDown($event, 'Tempo Breakdown')"
					></TempoBreakdown>
				</div>

				<div class="chart-container" v-if="typebreakdowndata.length > 0">
					<p class="chart-heading">Type Breakdown</p>
					<TypeBreakdown :chartdata="typebreakdowndata" :chartcategories="typebreakdowncategories"
						v-on:songlistdrill="drillDown($event, 'Type Breakdown')"
					></TypeBreakdown>
				</div>

				<div class="chart-container" v-if="moodbreakdowndata.length > 0">
					<p class="chart-heading">Mood Breakdown</p>
					<MoodBreakdown :chartdata="moodbreakdowndata" :chartcategories="moodbreakdowncategories"
						v-on:songlistdrill="drillDown($event, 'Mood Breakdown')"
					></MoodBreakdown>
				</div>

				<div class="chart-container">
					<p class="chart-heading">Rotation Category Breakdown</p>
					<RotationCategoryBreakdown :chartdata="rotationbreakdowndata" :chartcategories="rotationbreakdowncategories"
						v-on:songlistdrill="drillDown($event, 'Song Rotation Breakdown')"
					></RotationCategoryBreakdown>

					<div>
						<p class="" style="font-size: 12px;">
							CURRENT: Song played on rotation less than 12hr
							<br>
							RECURRENT: Song played on rotation 12hr to 36hr
							<br>
							GOLD: Song played on rotation greater than 36hr
						</p>
						<br>
					</div>

				</div>

				<div style="height: 200px; width: 100%;"></div>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DrilldownHolder from "@/components/DrilldownHolder";
import SharedSongs from "@/components/station-fingerprint-charts/SharedSongs";
import EraBreakdown from "@/components/station-fingerprint-charts/EraBreakdown";
import SongCounts from "@/components/station-fingerprint-charts/SongCounts";
import PercentageMusic from "@/components/station-fingerprint-charts/PercentageMusic";
import TempoBreakdown from "@/components/station-fingerprint-charts/TempoBreakdown";
import TypeBreakdown from "@/components/station-fingerprint-charts/TypeBreakdown";
import MoodBreakdown from "@/components/station-fingerprint-charts/MoodBreakdown";
import RotationCategoryBreakdown from "@/components/station-fingerprint-charts/RotationCategoryBreakdown";
export default {
	name: "StationFingerprint",
	components: {
		RotationCategoryBreakdown,
		MoodBreakdown,
		TypeBreakdown,
		TempoBreakdown,
		PercentageMusic,
		SongCounts,
		EraBreakdown,
		SharedSongs,
		DrilldownHolder,
	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: true,
				period: false,
				property: false,
				daypart: true,
				logdate: false,
				startdate: false,
				enddate: false,
			},
			reportType: 'StationFingerprint', //for API URL
			fetching: false,
			started: false,
			reportTitle: "Brand DNA",

			heading: 'Brand DNA',
			reportPropertyType: '',

			distinctTracks: null,
			exclusiveTracks: null,
			totalSpins: null,

			///
			sharedsongsdata: [],
			sharedsongcategories: [],

			erabreakdowndata: [],
			erabreakdowncategories: [],

			songcountdata: [],
			songcountcategories: [],

			pctmusicdata: [],
			pctmusiccategories: [],

			tempobreakdowndata: [],
			tempobreakdowncategories: [],

			typebreakdowndata: [],
			typebreakdowncategories: [],

			moodbreakdowndata: [],
			moodbreakdowncategories: [],

			rotationbreakdowndata: [],
			rotationbreakdowncategories: [],
			///

			returndata: [],

			drilldownOverlayShow: false,
			drilldownReportType: '',
			drilldownitem: null,
			drilldownpropname: '',

			logDateStart: null,
			logEndDate: null,

			stationResults: [],
		}
	},
	computed: {

	},
	methods: {
		drillDownUniverse(stationid) {
			this.drilldownReportType = 'StationFingerprintUniverse';
			this.drilldownitem = {
				daypart: this.selections.daypart,
				stationId: stationid,
			};
			this.drilldownOverlayShow = true;
		},
		drillDown(propname, reportname) {
			let mytrackarr = []
			let stnid = this.selections.stationid
			if(reportname === 'Era Breakdown') mytrackarr = this.returndata.decadeSongIds[stnid]
			else if(reportname === 'Tempo Breakdown') mytrackarr = this.returndata.tempoSongIds[stnid]
			else if(reportname === 'Type Breakdown') mytrackarr = this.returndata.genreSongIds[stnid]
			else if(reportname === 'Mood Breakdown') mytrackarr = this.returndata.moodSongIds[stnid]
			else if(reportname === 'Song Rotation Breakdown') mytrackarr = this.returndata.rotationSongIds[stnid]
			this.drilldownReportType = 'StationFingerprintDrilldown'
			this.drilldownitem = {
				daypart: this.selections.daypart,
				stationId: this.selections.stationid,
				report: reportname,
				trackIds: mytrackarr
			};
			this.drilldownpropname = propname
			this.drilldownOverlayShow = true
		},
		songlistDrilldown(drilltype, compstid) {
			this.drilldownReportType = drilltype;
			this.drilldownitem = {
				daypart: this.selections.daypart,
				stationId: this.selections.stationid,
				compStationId: compstid,
			};
			this.drilldownOverlayShow = true;
		},
		getReport() {
			if(this.fetching === false) {
				this.drilldownOverlayShow = false; //force hide the drilldown reports
				if(this.selections.marketid === null || this.selections.stationid === null || this.selections.daypart === null) {
					return false
				}
				let self = this
				let parstr = "marketId="+this.selections.marketid+"&stationId="+this.selections.stationid + "&daypartName=" + this.selections.daypart
				let targ = '/api-web/station-fingerprint-dna.php?'+parstr
				this.$store.commit('setLastQueryUrl', targ)
				this.fetching = true
				document.title = "Fetching data..."
				axios.get(targ).then(function(response) {
					let ret = response.data //response.data is returned info
					let mainStationId = self.selections.stationid
					document.title = 'Tracka - '+self.reportTitle
					self.returndata = ret
					self.distinctTracks = ret.distinctTracksByStation[mainStationId]
					self.totalSpins = ret.totalSpinsByStation[mainStationId]
					self.exclusiveTracks = ret.nonSharedTracksByStation[mainStationId]

					//shared songs
					let dat = []
					dat.push({x: 'Exclusive', y: ret.sharedSongIds[mainStationId][mainStationId].length, compstid: mainStationId})
					for(let stid of ret.sharedStationOrder[mainStationId]) {
						if(stid !== mainStationId) {
							let stn = self.getStationNameFromId(parseInt(stid))
							dat.push({x: stn, y: ret.sharedSongIds[mainStationId][stid].length, compstid: parseInt(stid)})
						}
					}
					self.sharedsongsdata = [{data: dat, name: 'Shared songs'}]

					//era breakdown
					dat = []
					let cats = []
					let tot = ret.decadesByStation[mainStationId]['total']
					for(let sc in ret.decadesByStation[mainStationId]) {
						if(sc === 'total' || sc === 'totals') continue
						cats.push(sc)
						dat.push(parseInt(ret.decadesByStation[mainStationId][sc] / tot * 100))
					}
					self.erabreakdowndata = dat
					self.erabreakdowncategories = cats

					//song counts
					dat = []
					for(let sc in ret.songCountsByStation) {
						let stn = self.getStationNameFromId(parseInt(sc))
						dat.push({x: stn, y: ret.songCountsByStation[sc], stationid: parseInt(sc)})
					}
					self.songcountdata = [{data: dat, name: 'Song counts'}]

					//percent music
					dat = []
					for(let sc in ret.pctMusicByStation) {
						let stn = self.getStationNameFromId(parseInt(sc))
						dat.push({x: stn, y: ret.pctMusicByStation[sc]})
					}
					self.pctmusicdata = [{data: dat, name: 'Percent music'}]

					//tempo breakdown
					dat = []
					cats = []
					if(ret.temposByStation[mainStationId]) {
						tot = ret.temposByStation[mainStationId]['total']
						for (let sc in ret.temposByStation[mainStationId]) {
							if (sc === 'total') continue
							cats.push(sc)
							dat.push(parseInt(ret.temposByStation[mainStationId][sc] / tot * 100))
						}
					}
					self.tempobreakdowndata = dat
					self.tempobreakdowncategories = cats

					//genre/type breakdown
					dat = []
					cats = []
					if(ret.genreByStation[mainStationId]) {
						tot = ret.genreByStation[mainStationId]['total']
						for (let sc in ret.genreByStation[mainStationId]) {
							if (sc === 'total') continue
							cats.push(sc)
							dat.push(parseInt(ret.genreByStation[mainStationId][sc] / tot * 100))
						}
					}
					self.typebreakdowndata = dat
					self.typebreakdowncategories = cats

					//mood breakdown
					dat = []
					cats = []
					if(ret.moodByStation[mainStationId]) {
						tot = ret.moodByStation[mainStationId]['total']
						for (let sc in ret.moodByStation[mainStationId]) {
							if (sc === 'total') continue
							cats.push(sc)
							dat.push(parseInt(ret.moodByStation[mainStationId][sc] / tot * 100))
						}
					}
					self.moodbreakdowndata = dat
					self.moodbreakdowncategories = cats

					//rotation breakdown
					dat = []
					cats = []
					tot = ret.rotationByStation[mainStationId]['total']
					for(let sc in ret.rotationByStation[mainStationId]) {
						if(sc === 'total') continue
						cats.push(sc)
						dat.push(parseInt(ret.rotationByStation[mainStationId][sc] / tot * 100))
					}
					self.rotationbreakdowndata = dat
					self.rotationbreakdowncategories = cats

					//send back
					self.fetching = false;
					self.started = true; //first one has come back
					self.heading = self.getStationNameFromId(self.selections.stationid)+" Brand DNA report (past 28 days)";
					self.sendLoggingInfo(self.reportType, self.selections);
				}).catch(function(error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects);
		document.title = 'Tracka - '+this.reportTitle;
	}
}
</script>

<style scoped>
.structure {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.chart-container {
	width: calc(50% - 20px);
	margin-bottom: 80px;
	height: 600px;
}
.chart-heading {
	margin: 20px 0 5px;
	font-size: 20px;
}

</style>